.App {
  text-align: center;
}

.App-content {
  background-color: #152f54;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  font-size: calc(100vw / 375 * 20);
  
}

.belly-icon{
  width: calc(100vw / 375 * 280);
}

@media(min-width: 768px){
  .App-content {
    font-size: min(calc(100vw / 1440 * 36), 36px);
  }

  .belly-icon{
    width: min(calc(100vw / 1440 * 512), 512px);
  }
}

